<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- 选项卡切换 -->
    <div class="tab">
      <ul class="tab_list">
        <li
          class="tab_item"
          :class="{ tab_active: index === currentIndex }"
          v-for="(item, index) in tabList"
          :key="item.id"
          @click="tabItemClick(index, item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="content_list">
        <div class="content_item" v-for="item in dataList" :key="item.id">
          <div class="top">
            <div class="img">
              <img src="../../assets/img/list1.png" alt="" v-if="item.id == 1" />
              <img src="../../assets/img/list1.png" alt="" v-if="item.id == 2" />
              <img src="../../assets/img/list1.png" alt="" v-if="item.id == 3"/>
              <img src="../../assets/img/cases/cover1.png" alt="" v-if="item.id == 4" />
              <img src="../../assets/img/cases/cover2.png" alt="" v-if="item.id == 5"/>
              <img src="../../assets/img/cases/cover3.png" alt="" v-if="item.id == 6"/>
            </div>
            <div class="date">
              <span class="name">{{ item.name }}</span>
              <span>{{ item.date }}</span>
            </div>
          </div>
          <div class="center">
            <p>{{ item.content }}</p>
          </div>
          <div class="btn_box">
            <div class="btn" @click="toDetail(item.id, item.name)">查看详情</div>
          </div>
        </div>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      orgUrl: "",
      tabList: [
        { id: 1, name: "全部" },
        { id: 2, name: "政府机构" },
        { id: 3, name: "银行机构" },
        { id: 4, name: "中大型企业" },
      ],
      currentIndex: 0,
      dataList: [],
      // 全部合作案例
      allList: [
        {
          id: 1,
          name: "泉州银行电子函证项目",
          content:
            "电子函证系统是面向银行函证业务的全方位、全流程服务的函证管理平台。",
          date: "2023-08",
          img: "/img/list1.png",
        },
        {
          id: 2,
          name: "甘肃省联社银企对账",
          date: "2021-12",
          content:
            "银企对账系统助力实现对账工作方式多样化、快捷化。为客户提供高效服务，为银行银企对账工作的开展提供有力支持。",
          img: "/img/list1.png",
        },
        {
          id: 3,
          name: "甘肃省联社电子回单",
          date: "2021-12",
          content:
            "电子回单系统为银行客户提供便捷服务，轻松管理交易记录，随时随地查看交易详情，并确保交易安全可靠。",
          img: "/img/list1.png",
        },
        {
          id: 4,
          name: "K12智慧校园场景",
          date: "2023-03",
          img: "/img/cases/cover1.png",
          content:
            "K12方案是以“数字校园”建设的中的食堂消费为场景，使用“刷脸消费”新型科技技术，以解决学生在校消费问题、食堂收款管理问题为目标",
        },
        {
          id: 5,
          name: "企业智慧团餐场景",
          date: "2023-09",
          img: "/img/cases/cover2.png",
          content:
            "企业智慧团餐将计算机、大数据、物联网等新技术应用于传统食堂，提高食堂信息化的实现程度",
        },
        {
          id: 6,
          name: "餐饮、零售等本地生活场景",
          date: "2023-05",
          img: "/img/cases/cover3.png",
          content:
            "面向餐饮、零售、美业等本地生活服务业商家 提供软硬一体的SaaS整体解决方案，提供涵盖点单、收银、预定、排队、外卖、报表、后厨管理等消费全流程的效率提升工具，帮助商家实现数字化、智能化升级，最终实现 “店开天下，客如云来”的愿景。",
        },
      ],
      govList: [],
      bankList: [
        {
          id: 1,
          name: "泉州银行电子函证项目",
          content:
            "电子函证系统是面向银行函证业务的全方位、全流程服务的函证管理平台。",
          date: "2023-08",
        },
        {
          id: 2,
          name: "甘肃省联社银企对账",
          date: "2021-12",
          content:
            "银企对账系统助力实现对账工作方式多样化、快捷化。为客户提供高效服务，为银行银企对账工作的开展提供有力支持。",
        },
        {
          id: 3,
          name: "甘肃省联社电子回单",
          date: "2021-12",
          content:
            "电子回单系统为银行客户提供便捷服务，轻松管理交易记录，随时随地查看交易详情，并确保交易安全可靠。",
        },
      ],
      enterpriseList: [
        {
          id: 4,
          name: "K12智慧校园场景",
          date: "2023-03",
          content:
            "K12方案是以“数字校园”建设的中的食堂消费为场景，使用“刷脸消费”新型科技技术，以解决学生在校消费问题、食堂收款管理问题为目标",
        },
        {
          id: 5,
          name: "企业智慧团餐场景",
          date: "2023-09",
          content:
            "企业智慧团餐将计算机、大数据、物联网等新技术应用于传统食堂，提高食堂信息化的实现程度",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    tabItemClick(index, id) {
      this.currentIndex = index;
      if (id == 1) {
        this.dataList = this.allList;
      }
      if (id == 2) {
        this.dataList = this.govList;
      }
      if (id == 3) {
        this.dataList = this.bankList;
      }
      if (id == 4) {
        this.dataList = this.enterpriseList;
      }
    },
    toDetail(id, name) {
      sessionStorage.setItem("caseTitle", name);
      this.$router.push({
        path: "/cases/detail",
        query: { id: id },
      });
    },
  },
  created() {
    this.dataList = this.allList;
    this.orgUrl = window.location.origin;
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
  padding-bottom: 40px;
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.tab {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.tab_list {
  display: flex;
  align-items: center;
}

.tab_item {
  padding: 10px;
  font-size: 14px;

  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #dcdfe6;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
}

.tab_item:first-child {
  border-left: 1px solid #dcdfe6;
  border-radius: 4px 0 0 4px;
}

.tab_item:last-child {
  border-radius: 0 4px 4px 0;
}

.tab_active {
  background: #e8f0ff;
}

/* 内容 */
.content {
  width: 1200px;
  margin: 0 auto;
}

.content_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.content_list > span {
  width: 30%;
}

.content_item {
  width: 30%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
    0 2px 4px 0 rgba(232, 237, 250, 0.5);
  margin-bottom: 40px;
}

.top {
  border-bottom: 1px solid rgba(166, 166, 166, 0.4);
}

.img {
  width: 100%;
  height: 200px;
}

.img img {
  display: block;
  width: 100%;
  height: 100%;
}

.date {
  height: 70px;
  padding: 10px 20px 0;
}

.date span {
  text-align: left;
  display: block;
  font-size: 14px;
  color: #606266;
  margin: 5px 0;
}

.date span:first-child {
  font-size: 18px;
  font-weight: 600;
}

.center {
  padding: 10px 20px;
}

.center p {
  text-align: left;
  font-size: 14px;
  color: #a6a6a6;
  overflow: hidden;
  display: -webkit-box; /* 为了兼容性，需要加上前缀 */
  -webkit-line-clamp: 2; /* 行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.btn_box {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
}

.btn {
  width: 80%;
  padding: 10px;
  border: 2px solid #83adff;
  border-radius: 25px;
  text-align: center;
  color: #1162ff;
  margin: 0 auto;
  cursor: pointer;
}

.btn:hover {
  color: #fff;
  border: 2px solid #1162ff;
  background: #1162ff;
}
</style>
